import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useEventContext } from '../../../contexts/EventProvider'
import { useMedia } from '../../../hooks/useMedia'
import { useSelector, shallowEqual } from 'react-redux'
import NavBar from '../../atoms/NavBar'
import { HStack, Spinner, useDisclosure } from '@chakra-ui/react'
import BackButton from '../../atoms/nav-controls/BackButton'
import { Box, Heading, Container, Text, Slide, Button, Badge } from '@chakra-ui/react'
import Card from '../../atoms/Card'
import FooterBase from '../../molecules/FooterBase'
import { useVideoDurationCalc } from '../../../hooks/useVideoDurationCalc'
import { maxMediaCount, maxMediaLength } from '../../../../../client/config.client'
import useScrollTopOnMount from '../../../hooks/useScrollTopOnMount'
import { useEventOccasion } from '../../../hooks/useEventOccasion'
import useUppyURL from '../../organisms/Uppy/src/UploaderURL'

import UnsplashSearch from './UnsplashSearch'
import { trackDownload } from './hooks/useStockPhotos'

import axios from 'axios'

const allowedFileTypes = ['video/*', 'image/*'] //['image/*', 'video/*', 'video/3gpp', 'video/x-matroska', 'video/x-flv']

const BadgeWithCount = ({ count }) => (
	<Badge fontSize="1rem" borderRadius="xl" px="0.35rem" color="link" backgroundColor="white" variant="solid">
		{count}
	</Badge>
)

const Controls = ({ isUploading, handleBack }) => {
	return (
		<NavBar>
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={handleBack} disabled={isUploading} />
			</HStack>
		</NavBar>
	)
}

const UploadUnsplash = () => {
	useScrollTopOnMount()
	const { uuid } = useParams()
	const { event } = useEventContext()
	const media = useMedia()
	const navigate = useNavigate()
	const { isOpen, onOpen, onClose } = useDisclosure()

	/** Calculate Total Video Length in Seconds */
	const { durationSec } = useVideoDurationCalc(media, event.themeTemplateId)
	const eventOccasion = useEventOccasion()
	/** Media Limits - prevent upload */
	let videoLengthInSec = media.length > 0 ? durationSec : 0
	const overLimit = media.length >= maxMediaCount || videoLengthInSec >= maxMediaLength

	const handleBack = () => navigate(`/event/${uuid}#addMedia`)

	if (overLimit) {
		handleBack()
	}

	const uppy = useUppyURL({
		event: event,
		handleClose: handleBack,
		maxNumberOfFiles: 10,
		allowedFileTypes: allowedFileTypes,
	})

	const occasionLabel = eventOccasion.label

	const [data, setData] = useState([])
	const [selectedUrls, setSelectedUrls] = useState([])
	const [isUploading, setIsUploading] = useState(false)

	useEffect(() => {
		if (selectedUrls.length > 0) {
			onOpen()
		} else {
			onClose()
		}
	}, [selectedUrls])

	const handleOnClick = (id) => {
		/** copy current selected array */
		var urlsArray = [...selectedUrls]
		/** Get index within selected array */
		var index = urlsArray.indexOf(id)

		const addFile = () => {
			setSelectedUrls([...urlsArray, id])
		}
		const removeFile = () => {
			urlsArray.splice(index, 1)
			setSelectedUrls(urlsArray)
		}
		/** call add or remove function */
		index === -1 ? addFile() : removeFile()
	}

	const handleOnUpload = () => {
		setIsUploading(true)
		if (uppy?.addFile && selectedUrls.length > 0) {
			selectedUrls.forEach((id) => {
				let image = data.filter((i) => i.id == id)[0]
				if (image?.urls) {
					uppy.getPlugin('Url')
						.addFile(image?.urls.full)
						.then(() => {
							// register download event with unsplash
							if (image.links.download_location) {
								trackDownload({
									downloadLocation: image.links.download_location,
								})
							}
							const files = uppy.getFiles()
							if (files.length > 0 && files.length === selectedUrls.length) {
								uppy.upload()
							}
						})
				}
			})
		}
	}

	return (
		<>
			<Controls isUploading={isUploading} handleBack={handleBack} />

			<Container maxW="container.md" py="1rem" variant="main">
				<Box my="2rem" textAlign={['left', 'center']} mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Add an Image
					</Heading>

					<Text>Select beautiful stock images to add to your video.</Text>
				</Box>
				<Card
					p="1rem"
					alignItems="center"
					variant="main"
					mb="2rem"
					textAlign="center"
					mx={['1rem', 0]}
					borderRadius={['xl']}>
					{!isUploading ? (
						<UnsplashSearch
							handleOnClick={handleOnClick}
							setData={setData}
							data={data}
							defaultSearch={
								eventOccasion.type != 'other'
									? eventOccasion.type == 'memorial'
										? 'Condolences'
										: occasionLabel
									: 'Celebrate'
							}
							selectedUrls={selectedUrls}
						/>
					) : (
						<HStack>
							<Text variant="title">Uploading</Text>
							<Spinner />
						</HStack>
					)}
				</Card>

				<Slide direction="bottom" in={isOpen} style={{ zIndex: 10 }}>
					<Box
						textAlign="center"
						background="white"
						borderTop="2px"
						borderColor="gray.100"
						w="full"
						py="1rem">
						<Button
							variant="solid"
							rightIcon={<BadgeWithCount count={selectedUrls.length} />}
							onClick={handleOnUpload}
							disabled={isUploading || selectedUrls.length == 0 || selectedUrls.length > 10}>
							Upload Selected
						</Button>
						{selectedUrls.length > 10 && (
							<Text mt="0.5rem">
								The limit per upload at once is <strong>10 files</strong>.
							</Text>
						)}
					</Box>
				</Slide>
			</Container>
			<FooterBase />
		</>
	)
}

export default UploadUnsplash
