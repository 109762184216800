import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import Gallery from '../Gallery'
import useEventListener from '../../../../hooks/useEventListener'
import useStockPhotos from '../hooks/useStockPhotos'
import { Box, InputGroup, InputRightElement, Input, Badge, Spinner } from '@chakra-ui/react'

const BadgeWithCount = ({ count }) => (
	<Badge mt="0.5rem" borderRadius="xl" px="0.35rem" color="link" backgroundColor="white" variant="solid">
		{count}
	</Badge>
)

const galleryStyles = {
	'my': 0,
	'mx': 'auto',
	'& .gallery': {
		w: 'full',
	},
	'& .gallery:after': {
		content: '""',
		clear: 'both',
		display: 'block',
		width: '100%',
	},
	'& .gallery .grid-photos': {
		padding: '0.5%',
		float: 'left',
		display: 'inline-block',
	},
	'& .photo-wrapper': {
		marginBottom: '3%',
		position: 'relative',
	},
	'& .photo-wrapper .photo-container': {
		position: 'relative',
		background: '#eee',
		borderRadius: '5px',
	},
	'& .photo-wrapper .photo-img': {
		position: 'absolute',
		top: 0,
		left: 0,
		width: '100%',
		borderRadius: '5px',
		cursor: 'pointer',
	},
	'& .overlay': {
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
		bottom: 0,
		backgroundColor: 'rgba(0, 0, 0, 0.4)',
		display: 'flex',
		color: 'white',
		justifyContent: 'center',
		alignItems: 'center',
		borderRadius: '5px',
		cursor: 'pointer',
		pointerEvents: 'none',
		zIndex: 1,
	},
	'& .credit, & .credit a': {
		fontSize: '12px',
		lineHeight: '15px',
		color: '#ffffff',
		zIndex: 2,
		textShadow: '0 0 2px black',
		fontFamily: 'Roboto, Helvetica Neue, sans-serif',
		lineHeight: '8px',
	},

	'& .credit': {
		display: 'none',
		position: 'absolute',
		margin: '4px 6px',
		cursor: 'pointer',
	},

	'& .credit a:hover': {
		textDecoration: 'underline',
	},

	'& .photo-container:hover .credit': {
		display: 'block',
	},
}

/* **************************************
DEFAULT RETURN
************************************** */

const UnsplashSearch = ({ setData, data, handleOnClick, defaultSearch, selectedUrls }) => {
	const unsplashGridRef = useRef()
	useEffect(() => {
		if (unsplashGridRef?.current) {
			setWidth(unsplashGridRef?.current?.offsetWidth)
		}
	}, [unsplashGridRef])

	const [width, setWidth] = useState(320)
	const [search, setSearch] = useState(defaultSearch)
	const [query, setQuery] = useState(defaultSearch)
	const [page, setPage] = useState(1)
	const [totalResults, setTotalResults] = useState(null)
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		var term = query != '' ? query : defaultSearch
		const typeTimer = setTimeout(() => {
			setSearch(term)
		}, 1200)
		return () => clearTimeout(typeTimer)
	}, [query])

	useEffect(() => {
		useStockPhotos({ page: 1, search })
			.then((reply) => {
				setData(reply?.data?.results)
				setTotalResults(reply?.data?.total)
				setPage(1)
			})
			.catch((err) => {
				console.log(err)
				setTotalResults('Error loading images, please try again')
			})
	}, [search])

	const loadMore = () => {
		/** update page count */
		var getPage = page + 1
		setPage(getPage)
		useStockPhotos({ page: getPage, search })
			.then((reply) => {
				var moreData = reply?.data?.results
				var oldData = data
				setData([...oldData, ...moreData])
				setLoading(false)
			})
			.catch((err) => {
				console.log(err)
				setTotalResults('Error loading images, please try again')
			})
	}

	useEventListener(
		'scroll',
		() => {
			if (unsplashGridRef.current.offsetHeight < window.innerHeight + document.documentElement.scrollTop) {
				if (totalResults != data.length && !loading) {
					setLoading(true)
					loadMore()
				}
			}
		},
		window
	)

	// Render the React Component and pass it your fetchGifs as a prop
	return (
		<Box w="full" ref={unsplashGridRef}>
			<InputGroup mb="1rem">
				<Input
					size="lg"
					placeholder="Search Stock Images"
					name="search"
					onChange={(e) => setQuery(e.target.value)}
					defaultValue={search}
				/>
				<InputRightElement
					children={<BadgeWithCount count={totalResults ? `${totalResults} results` : `Search Images`} />}
					w="125px"
				/>
			</InputGroup>

			<Box sx={galleryStyles} key={search}>
				<Gallery
					data={data}
					columns={width > 414 ? 3 : 2}
					handleOnClick={handleOnClick}
					selectedUrls={selectedUrls}
				/>
				{typeof totalResults === 'string' ? (
					<h5>{totalResults}</h5>
				) : totalResults != data.length ? (
					<Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="gray.500" size="md" />
				) : (
					<h5>End of Results</h5>
				)}
			</Box>
		</Box>
	)
}

/* **************************************
DEFAULT PROPS VALUE
************************************** */

UnsplashSearch.defaultProps = {
	limit: 16,
	onClick: null,
	defaultSearch: 'celebrate',
	selectedUrls: [],
}

/* **************************************
COMPONENT PROPS TYPES
************************************** */

UnsplashSearch.propTypes = {
	limit: PropTypes.number,
	handleOnClick: PropTypes.func.isRequired,
	defaultSearch: PropTypes.string,
	selectedUrls: PropTypes.array,
}
export default UnsplashSearch
