import axios from 'axios'
import configClient from '../../../../../../client/config.client'

const useStockPhotos = ({ page = 1, search = 'celebrate' }) => {
	return axios.post(configClient.media.stockImagesUrlSearch, { page, search })
}

export const trackDownload = ({ downloadLocation }) => {
	return axios.post(configClient.media.stockImagesUrlTrack, { downloadLocation })
}

export default useStockPhotos
