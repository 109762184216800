import React, { useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { Spinner } from '@chakra-ui/react'
import { CheckCircleRounded } from '../../../atoms/Icon'

/*** PHOTO */
const Photo = ({ id, src, height, width, user, handleOnClick, selectedUrls }) => {
	return (
		<div className="photo-wrapper" onClick={() => handleOnClick(id)}>
			<div
				className="photo-container"
				style={{
					paddingBottom: (height / width) * 100 + '%',
				}}>
				<img className="photo-img" src={src} />
				<span className="credit">
					Photo by{' '}
					<a target="_blank" href={`https://unsplash.com/@${user.username}`} rel="noreferrer">
						{user.name}
					</a>{' '}
					on{' '}
					<a
						target="_blank"
						href="https://unsplash.com/?utm_source=vidday_media_inc&utm_medium=referral"
						rel="noreferrer">
						Unsplash
					</a>
				</span>
				{selectedUrls.includes(id) && (
					<div className="overlay">
						<CheckCircleRounded />
					</div>
				)}
			</div>
		</div>
	)
}

const Gallery = ({ data, selectedUrls, handleOnClick, columns = 2 }) => {
	const [grids, setGrids] = useState([])

	const createGridColumns = (array, size) => {
		const fillers = []

		for (let i = 0; i < size; i++) {
			fillers.push([])
		}

		return array.reduce((ac, value, index) => {
			const colIdx = index % size
			ac[colIdx].push(value)
			return ac
		}, fillers)
	}

	useEffect(() => {
		const images = data?.map((photo) => ({
			id: photo.id,
			src: photo.urls.small,
			height: photo.height,
			width: photo.width,
			user: photo.user,
		}))
		if (images) setGrids(createGridColumns(images, columns))
	}, [data])

	if (data === null) {
		return <Spinner thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
	} else {
		return (
			<div className="gallery">
				{grids.map((images, index) => (
					<div key={index} className="grid-photos" style={{ width: 100 / grids.length + '%' }}>
						{images?.map((photo, indexChild) => (
							<Photo
								key={`${index}-${indexChild}`}
								{...photo}
								handleOnClick={handleOnClick}
								selectedUrls={selectedUrls}
							/>
						))}
					</div>
				))}
			</div>
		)
	}
}

Gallery.propTypes = {
	data: PropTypes.array,
	columns: PropTypes.number.isRequired,
	handleOnClick: PropTypes.func.isRequired,
	selectedUrls: PropTypes.array,
}

export default Gallery
